<template>
  <div>
    <v-row class="ma-0 my-4 align-center justify-space-between">
      <h1 class="text-h5 font-weight-bold">Solicitudes</h1>
    </v-row>

    <v-card class="pa-4 elevation-1">
      <v-row class="px-5 py-5" style="justify-content: space-between; align-items: center">
        <v-col cols="12" md="6" class="my-0 py-0">
          <v-text-field
            v-model="stringToSearch"
            label="Buscar por correo"
            :disabled="loadingData"
            placeholder="Correo del colaborador..."
            dense
            hide-details
            outlined
            append-icon="mdi-magnify"
            @click:append="searchString"
            @keyup.enter="searchString"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6" class="my-0 py-0">
          <v-container class="ma-0 pa-0 mt-3 mt-md-0 d-flex align-center justify-end">
            <div class="d-flex flex-column justify-end align-center mr-3 ml-6">
              <v-select
                v-model="companySelected"
                :items="companies"
                item-text="name"
                item-value="id"
                label="Filtrar por empresa"
                return-object
              ></v-select>
            </div>

            <v-btn class="mb-3" color="primary" depressed @click="() => downloadCsv(6)">
              <v-icon dark>mdi-file-download-outline</v-icon>
              <span>CSV</span>
            </v-btn>
          </v-container>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="requests"
        :options.sync="options"
        :loading="loadingData"
        :items-per-page="limit"
        :server-items-length="totalItems"
        :sort-desc.sync="sort"
        :disable-sort="loadingData"
        multi-sort
        class="mt-3 elevation-0"
        :footer-props="{
          'items-per-page-options': [10, 15, 20]
        }"
        @update:options="fetchMemberships"
      >
        <template v-slot:item.collaborator="{ item }">
          <span>{{ item.collaborator || '—' }}</span>
        </template>

        <template v-slot:item.email="{ item }">
          <span>{{ item.email || '—' }}</span>
        </template>

        <template v-slot:item.pet="{ item }">
          <template v-if="item.species === 'cat'">
            <v-chip small color="green" class="white--text font-weight-bold">
              <v-icon left>mdi-cat</v-icon>
              {{ item.pet }}
            </v-chip>
          </template>
          <template v-else-if="item.species === 'dog'">
            <v-chip small color="orange" class="white--text font-weight-bold">
              <v-icon left>mdi-dog</v-icon>
              {{ item.pet }}
            </v-chip>
          </template>
          <template v-else>
            <span>{{ item.pet || '—' }}</span>
          </template>
        </template>

        <template v-slot:item.company="{ item }">
          <span>{{ item.company || '—' }}</span>
        </template>

        <template v-slot:item.plan="{ item }">
          <span>{{ item.plan || '—' }}</span>
        </template>

        <!-- Edit pencil -->
        <template v-slot:item.request="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                fab
                dark
                depressed
                x-small
                color="blue"
                v-bind="attrs"
                v-on="on"
                @click="openEditDialog(item)"
              >
                <v-icon small dark>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog" max-width="800px" style="font-family: 'Inter', sans-serif;">
      <v-card class="pa-5" style="border-radius: 8px;">
        <v-overlay :value="dialogLoading" absolute>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-overlay>
        <v-card-title class="text-h6 font-weight-bold">
          MEMBRESÍA DE
          {{
            (selectedItem &&
              selectedItem.collaborator.toUpperCase() + ' Y ' + selectedItem.pet.toUpperCase()) ||
              '-'
          }}
        </v-card-title>

        <v-card-text>
          <v-container class="pa-0">
            <v-row>
              <!-- Left Column -->
              <v-col cols="4">
                <p>
                  <strong>Nombre:</strong>
                  {{ (selectedItem && selectedItem.collaborator) || '—' }}
                </p>
                <p><strong>RUT:</strong> {{ selectedItem && selectedItem.rut }}</p>

                <p>
                  <strong>Correo:</strong>
                  {{ (selectedItem && selectedItem.email) || 'correo@corporativo.com' }}
                </p>
                <p><strong>Mascota:</strong> {{ (selectedItem && selectedItem.pet) || '—' }}</p>
              </v-col>

              <!-- Right column -->
              <v-col cols="4">
                <p><strong>Empresa:</strong> {{ (selectedItem && selectedItem.company) || '—' }}</p>

                <p>
                  <strong>Mes pago:</strong>
                  {{ (selectedItem && selectedItem.activation) || 'XX/XX/XXXX' }}
                </p>
                <p>
                  <strong>Días proporcionales:</strong>
                  {{ (selectedItem && selectedItem.monthPaid) || 'XX' }}
                </p>
              </v-col>
              <v-col cols="4">
                <p><strong>Ref.:</strong> {{ (selectedItem && selectedItem.ref) || '—' }}</p>
                <p><strong>Plan:</strong> {{ (selectedItem && selectedItem.plan) || '—' }}</p>
                <p>
                  <strong>Valor plan (S/IVA):</strong> ${{ selectedItem && selectedItem.planValue }}
                </p>
                <p>
                  <strong>Valor plan (C/IVA):</strong> ${{
                    selectedItem && selectedItem.planValueIVA
                  }}
                </p>
              </v-col>
            </v-row>
            <v-divider class="mt-4" color="#EAEEF4" style="height: 4px;"></v-divider>
            <v-row class="mt-4 align-center justify-space-between">
              <!-- Approval button -->
              <v-col cols="6" class="d-flex justify-center">
                <v-btn
                  style="
      border-radius: 8px;
      text-transform: none;
      min-width: 104px;
      min-height: 56px;
      padding: 14px 104px;
    "
                  :color="confirmation === 'approve' ? '#24D07A' : '#D3F6E4'"
                  depressed
                  @click="confirmation = 'approve'"
                >
                  <div class="d-flex align-center">
                    <v-radio
                      v-model="confirmation"
                      value="approve"
                      hide-details
                      color="#24D07A"
                      style="pointer-events: none;"
                    />
                    <span
                      :style="{
                        color: confirmation === 'approve' ? 'white' : 'black',
                        fontWeight: 'bold'
                      }"
                    >
                      Aprobar
                    </span>
                  </div>
                </v-btn>
              </v-col>
              <v-col cols="6" class="d-flex justify-center">
                <v-btn
                  style="
      border-radius: 8px;
      text-transform: none;
      min-width: 104px;
      min-height: 56px;
      padding: 14px 104px;
    "
                  :color="confirmation === 'reject' ? '#E5515B' : '#F8D4D6'"
                  depressed
                  @click="confirmation = 'reject'"
                >
                  <div class="d-flex align-center">
                    <v-radio
                      v-model="confirmation"
                      value="reject"
                      hide-details
                      color="#E5515B"
                      style="pointer-events: none;"
                    />
                    <span
                      :style="{
                        color: confirmation === 'reject' ? 'white' : 'black',
                        fontWeight: 'bold'
                      }"
                    >
                      Rechazar
                    </span>
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider class="my-4" color="grey"></v-divider>
        <!-- Action buttons -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            text
            @click="confirmRequest"
            style="background-color: #104B94; border-radius: 50px; padding: 8px 14px;"
            >Aceptar</v-btn
          >
          <v-btn color="primary" text @click="closeDialog">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbarOk" color="green" :timeout="timeout">
      {{ successMessage }}

      <template v-slot:actions>
        <v-btn color="blue" variant="text" @click="snackbarOk = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="snackbarFailed" color="red" :timeout="timeout">
      {{ errorMessage }}

      <template v-slot:actions>
        <v-btn color="blue" variant="text" @click="snackbarFailed = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <overlay :value="loadingOverlay"></overlay>
  </div>
</template>

<script>
import Overlay from '../components/Overlay.vue';
import CompaniesService from '../services/companie.service';
import MembershipsService from '../services/memberships.service';
import CurrencyUtils from '../utils/currency.utils';
import RutUtils from '../utils/rut.utils';

export default {
  components: {
    Overlay
  },
  data() {
    return {
      loadingData: false,
      dialogLoading: false,
      loadingOverlay: false,
      stringToSearch: null,
      companies: [{ id: null, name: 'Todos' }],
      companySelected: { id: null, name: 'Todos' },
      requests: [],
      headers: [
        { text: 'Colaborador', value: 'collaborator', sortable: true },
        { text: 'Correo', value: 'email', sortable: true },
        { text: 'Mascota', value: 'pet', sortable: true },
        { text: 'Empresa', value: 'company', sortable: true },
        { text: 'Nombre Plan', value: 'plan', sortable: true },
        { text: 'Solicitud', value: 'request', sortable: false }
      ],
      options: {},
      limit: 10,
      totalItems: 0,
      sort: [],
      dialog: false,
      selectedItem: null, // modal selected Item
      confirmation: null, // request confirmation or rejection

      snackbarOk: false,
      snackbarFailed: false,
      successMessage: '',
      errorMessage: '',
      timeout: 3000
    };
  },
  methods: {
    async fetchMemberships() {
      this.loadingData = true;
      try {
        const { page, itemsPerPage } = this.options;
        const { status, data } = await MembershipsService.getDxpMemberships(
          page || 1,
          itemsPerPage || this.limit,
          this.stringToSearch || null,
          this.companySelected?.id || null
        );

        if (status === 'ok') {
          this.requests = data.memberships.map(m => ({
            id: m.id,
            collaborator: m.userName,
            email: m.email,
            pet: m.petName,
            company: m.companyName,
            plan: m.planName,
            species: m.species
          }));
          this.totalItems = data.total;
        } else {
          this.requests = [];
          this.totalItems = 0;
        }
      } catch (error) {
        console.error('Error fetching memberships:', error);
        this.requests = [];
        this.totalItems = 0;
      } finally {
        this.loadingData = false;
      }
    },
    searchString() {
      if (
        (this.stringToSearch && this.stringToSearch.length >= 3) ||
        !this.stringToSearch ||
        this.stringToSearch.length === 0
      ) {
        this.fetchMemberships();
      } else {
        console.error('El término de búsqueda debe tener al menos 3 caracteres.');
      }
    },
    async openEditDialog(item) {
      console.log('item:', item);
      this.selectedItem = null;
      this.dialogLoading = true;
      this.dialog = true;

      const { status, data } = await MembershipsService.getMembershipDetails(item.id);
      console.log('data:', data);
      if (status === 'ok') {
        // Mixed data from the service and the item
        this.selectedItem = {
          rut: RutUtils.formatRut(data.rut),
          ref: data.ref,
          planValue: CurrencyUtils.formatPriceFromNumber(data.priceWithoutTax),
          planValueIVA: CurrencyUtils.formatPriceFromNumber(data.price),
          monthPaid: data.daysDiff,
          activation: data.startDate,

          // Datatable item
          id: item.id,
          collaborator: item.collaborator,
          email: item.email,
          pet: item.pet,
          plan: item.plan,
          company: item.company
        };
      } else {
        console.error('No se pudieron obtener los detalles de la membresía.');
      }

      this.dialogLoading = false;
    },
    closeDialog() {
      this.dialog = false;
      this.selectedItem = null;
    },
    async confirmRequest() {
      if (this.confirmation === 'approve') {
        this.dialogLoading = true;
        const { status, message } = await MembershipsService.approveRequest(this.selectedItem.id);

        if (status === 'ok') {
          this.successMessage = 'Solicitud aprobada correctamente.';
          this.snackbarOk = true;
          this.fetchMemberships();
        } else {
          this.errorMessage = message || 'Error al aprobar la solicitud.';
          this.snackbarFailed = true;
          console.error(message || 'Error al aprobar la solicitud.');
        }
        this.dialogLoading = false;
      } else if (this.confirmation === 'reject') {
        this.dialogLoading = true;
        const { status, message } = await MembershipsService.rejectRequest(this.selectedItem.id);

        if (status === 'ok') {
          this.successMessage = 'Solicitud rechazada correctamente.';
          this.snackbarOk = true;
          this.fetchMemberships();
        } else {
          this.errorMessage = message || 'Error al rechazar la solicitud.';
          this.snackbarFailed = true;
          console.error(message || 'Error al rechazar la solicitud.');
        }
        this.dialogLoading = false;
      }
      this.closeDialog();
    },
    async getCompanies() {
      this.loadingData = true;
      const res = await CompaniesService.getCompanies(1, 100);
      if (res.status === 'ok') {
        this.companies = [...this.companies, ...res.data.companies];
      }
      this.loadingData = false;
    },
    async downloadCsv(status) {
      const { status: responseStatus, data } = await MembershipsService.getExportedDxpCsv(
        status,
        this.companySelected?.id || null,
        this.stringToSearch || null
      );

      if (responseStatus === 'ok') {
        console.log();
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `dxp_memberships_${new Date().toISOString().split('T')[0]}.xlsx`;
        link.click();
      } else {
        console.error('Error downloading CSV');
      }
    }
  },
  mounted() {
    this.fetchMemberships();
    this.getCompanies();
  },
  watch: {
    companySelected: {
      handler() {
        this.fetchMemberships();
      },
      deep: true
    }
  }
};
</script>
