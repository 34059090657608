<template>
  <div>
    <v-divider></v-divider>
    <v-container class="pa-5 ma-0 grey lighten-4">
      <v-form ref="recordForm" v-model="formValid">
        <v-container class="pa-0 ma-0">
          <v-row class="pt-4">
            <v-col class="my-0 py-0" cols="12" md="4">
              <v-select
                v-model="recordData.categoryId"
                :items="categories"
                label="Categoría"
                required
                outlined
                dense
                item-text="name"
                item-value="id"
                :disabled="loading || existingServiceRedemption"
                :loading="loading"
              ></v-select>
            </v-col>
            <v-col class="my-0 py-0" cols="12" md="8">
              <v-text-field
                label="Título"
                v-model="recordData.title"
                required
                outlined
                dense
                :rules="titleRules"
                :disabled="loading"
                :loading="loading"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="my-0 py-0" cols="6" md="3">
              <v-menu
                ref="dateMenu"
                v-model="dateMenu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="recordDateText"
                    label="Fecha"
                    :disabled="loading"
                    :loading="loading"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" @input="onDateTimeChange"> </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="my-0 py-0" cols="6" md="3">
              <v-menu
                ref="timeMenu"
                v-model="timeMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="recordTimeText"
                    label="Hora (Opcional)"
                    :disabled="loading"
                    :loading="loading"
                    readonly
                    outlined
                    @click:clear="time = null"
                    clearable
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker v-model="time" @input="onDateTimeChange" format="24hr">
                </v-time-picker>
              </v-menu>
            </v-col>

            <v-col class="my-0 py-0" cols="6" md="3">
              <v-text-field
                label="Gastos"
                prepend-inner-icon="mdi-currency-usd"
                v-model="recordData.expenses"
                required
                outlined
                placeholder="Gastos de esta atención"
                dense
                type="number"
                :disabled="loading"
                :loading="loading"
              ></v-text-field>
            </v-col>
            <v-col class="my-0 py-0" cols="6" md="3">
              <v-select
                v-model="recordData.expensesCurrency"
                :items="currencies"
                label="Divisa"
                required
                outlined
                dense
                :disabled="loading"
                :loading="loading"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="my-0 py-0" cols="12" md="5">
              <v-text-field
                label="Lugar"
                placeholder="Veterinaria, salón, etc"
                v-model="recordData.place"
                required
                outlined
                dense
                :disabled="loading"
                :loading="loading"
              ></v-text-field>
            </v-col>
            <v-col class="my-0 py-0" cols="12" md="5">
              <v-text-field
                label="Nombre del profesional"
                placeholder="Nombre del veterinario, etc"
                v-model="recordData.professional"
                required
                outlined
                dense
                :disabled="loading"
                :loading="loading"
              ></v-text-field>
            </v-col>
            <v-col class="my-0 py-0" cols="12" md="2">
              <v-text-field
                label="Peso"
                placeholder="Ej: 4.32"
                v-model="recordData.petWeight"
                required
                suffix="kg"
                outlined
                dense
                type="number"
                :disabled="loading"
                :loading="loading"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="my-0 py-0" cols="12">
              <v-textarea
                label="Anotaciones del Equipo Pawer"
                placeholder="El usuario podrá leer estas anotaciones, pero NO editarlas"
                v-model="recordData.adminDescription"
                required
                outlined
                dense
                rows="3"
                :disabled="loading"
                :loading="loading"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="my-1" v-if="!create">
            <v-col class="my-0 py-0" cols="12">
              <v-textarea
                label="Anotaciones del usuario"
                v-model="recordData.userDescription"
                required
                outlined
                rows="3"
                dense
                :loading="loading"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>

        <v-container
          class="ma-0 pa-0"
          v-if="plan != null && recordData.categoryId && category.adminOnly"
        >
          <v-col class="ma-0 pa-0">
            <h3 class="mb-4">Uso de servicio</h3>
            <v-row class="my-1">
              <v-col class="my-0 py-0" cols="5">
                <v-select
                  label="Servicio"
                  v-model="serviceRedemption.serviceId"
                  :items="services"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  item-text="title"
                  item-value="id"
                  :disabled="loading || existingServiceRedemption"
                  :loading="loading"
                ></v-select>
              </v-col>
              <v-col class="my-0 py-0" cols="3">
                <v-select
                  label="Divisa costo"
                  v-model="serviceRedemption.totalPriceCurrency"
                  :items="currencies"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  :disabled="loading || existingServiceRedemption"
                  :loading="loading"
                ></v-select>
              </v-col>
              <v-col class="my-0 py-0" cols="4">
                <v-text-field
                  label="Costo total"
                  prepend-inner-icon="mdi-currency-usd"
                  v-model="serviceRedemption.totalPrice"
                  required
                  outlined
                  placeholder="Costo total de la atención"
                  dense
                  type="number"
                  :disabled="loading"
                  :loading="loading"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="my-1">
              <v-col class="my-0 py-0" cols="3">
                <v-select
                  label="Divisa reembolso"
                  v-model="serviceRedemption.refundedPriceCurrency"
                  :items="currencies"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  :disabled="true"
                  :loading="loading"
                ></v-select>
              </v-col>
              <v-col class="my-0 py-0" cols="4">
                <v-text-field
                  label="Reembolso total"
                  prepend-inner-icon="mdi-currency-usd"
                  v-model="serviceRedemption.refundedPrice"
                  required
                  outlined
                  placeholder="Reembolso total disponible"
                  dense
                  type="number"
                  :disabled="true"
                  :loading="loading"
                ></v-text-field>
              </v-col>
              <v-col class="my-0 py-0" cols="5">
                <v-switch
                  class="mt-2 pt-0"
                  label="Cubierto por proveedor"
                  v-model="serviceRedemption.coveredByProvider"
                  dense
                  :disabled="loading"
                  :loading="loading"
                ></v-switch>
              </v-col>
            </v-row>
          </v-col>
        </v-container>
        <v-card-actions class="pa-0 ma-0">
          <v-btn small color="primary" :disabled="loading" @click="saveRecord()"> Guardar </v-btn>
          <v-btn small text color="primary" :disabled="loading" @click="close()"> Cancelar </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!create"
            small
            color="error"
            :disabled="loading || !deletionAvailable"
            @click="deleteRecord()"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-container>
    <v-snackbar v-model="error" :timeout="5000">
      {{ errorMessage ? errorMessage : 'Ocurrió un error al guardar el registro' }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="error = false"> Entendido </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import moment from 'moment';
import PetsService from '../services/pets.service';
import MembershipsService from '../services/memberships.service';
import DateUtils from '../utils/date.utils';

export default {
  name: 'RecordMaintainer',
  props: ['record', 'create', 'categories', 'plan', 'services', 'membership'],
  data() {
    return {
      recordData: null,
      currencies: ['CLP', 'UF'],
      loading: false,
      formValid: false,
      error: false,
      dateMenu: false,
      category: null,
      existingServiceRedemption: false,
      serviceRedemption: {},
      date: null,
      timeMenu: false,
      time: null,
      titleRules: [v => !!v || 'El campo es requerido'],
      editing: false,
      errorMessage: null,
      deletionAvailable: false
    };
  },
  beforeMount() {
    this.setRecord();
  },
  watch: {
    record() {
      this.setRecord();
    },
    editing() {
      this.setRecord();
    },
    time() {
      console.log('time watch', this.time);
    },
    categoryId() {
      this.setCategory();
    }
  },
  computed: {
    recordDateText() {
      return DateUtils.formatToChileanDateV2(this.recordData.date);
    },
    recordTimeText() {
      return this.recordData.withTime ? moment(this.recordData.date).format('HH:mm') : '';
    },
    categoryId() {
      if (this.recordData) {
        return this.recordData.categoryId;
      }
      return null;
    }
  },
  methods: {
    setRecord() {
      if (this.create) {
        this.editing = true;
      }
      this.recordData = { ...this.record } ?? this.recordData;
      this.date = new Date(this.recordData.date).toISOString().substr(0, 10);
      this.time = this.recordData.withTime ? moment(this.recordData.date).format('HH:mm') : null;

      this.setCategory();
      if (this.recordData.serviceRedemption) {
        this.existingServiceRedemption = true;
        this.serviceRedemption = { ...this.recordData.serviceRedemption };
      } else {
        this.deletionAvailable = true;
      }
    },
    setCategory() {
      if (this.recordData.categoryId) {
        const category = this.categories.find(cat => cat.id == this.recordData.categoryId);
        this.category = JSON.parse(JSON.stringify(category));
      }
    },
    async saveRecord() {
      this.loading = true;
      if (this.category) {
        this.recordData.categoryId = this.category.id;
      }

      if (
        this.serviceRedemption.serviceId &&
        this.serviceRedemption.totalPriceCurrency &&
        this.serviceRedemption.totalPrice
      ) {
        this.serviceRedemption.membershipId = this.membership.id;

        // Create or update standalone redemption
        if (!this.create && this.existingServiceRedemption && this.serviceRedemption.id) {
          await this.updateServiceRedemption();
        }
      }

      if (this.create) {
        await this.createRecord();
      } else {
        await this.updateRecord();
      }
      this.loading = false;
    },
    async deleteRecord() {
      this.loading = true;
      await PetsService.deleteRecord(this.recordData.id);
      this.loading = false;
      this.close();
      this.$emit('reload-records');
    },
    async createServiceRedemption() {
      await MembershipsService.createServiceRedemption(this.serviceRedemption);
    },
    async updateServiceRedemption() {
      await MembershipsService.updateServiceRedemption(
        this.serviceRedemption.id,
        this.serviceRedemption
      );
    },
    async createRecord() {
      this.recordData.withTime = this.time != null;

      // Set redemption info if exists
      if (this.serviceRedemption.membershipId) {
        this.recordData.serviceRedemption = this.serviceRedemption;
      }
      const response = await PetsService.createRecord(this.recordData);
      if (response.status === 'ok') {
        this.editing = false;
        this.$emit('reload-records');
        this.$emit('close-create');
      } else {
        this.error = true;
        this.errorMessage = response.message;
      }
    },
    async updateRecord() {
      console.log('date', this.date);
      console.log('time', this.time);
      console.log('dateTime', this.recordData.date);

      // Create redemption at record update
      if (!this.create && !this.existingServiceRedemption && this.serviceRedemption.membershipId) {
        this.recordData.serviceRedemption = this.serviceRedemption;
      }

      this.recordData.withTime = this.time != null;
      const response = await PetsService.updateRecord(this.recordData.id, this.recordData);
      if (response.status === 'ok') {
        this.editing = false;
        this.$emit('reload-records');
      } else {
        this.error = true;
        this.errorMessage = null;
      }
    },
    clearTime() {
      console.log('clearTime');
      this.time = null;
    },
    onDateTimeChange() {
      this.dateMenu = false;
      this.timeMenu = false;

      let dateTimeString = moment(this.date).format('DD-MM-YYYY');
      dateTimeString += ' ' + (this.time ? this.time : '00:00');
      this.recordData.withTime = this.time != null;

      this.recordData.date = moment(dateTimeString, 'DD-MM-YYYY HH:mm').toISOString();
    },
    close() {
      this.editing = false;
      if (!this.create) {
        this.recordData = { ...this.record };
      }
      this.$emit('close-create');
    }
  }
};
</script>

<style>
.v-card--hidden {
  display: none;
}

.v-text-field:not(.v-text-field--filled) .v-input__slot {
  background-color: white !important;
}
</style>
