var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"ma-0 my-4 align-center justify-space-between"},[_c('h1',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Solicitudes de Reembolso")]),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","md":"6"}},[_c('v-container',{staticClass:"ma-0 pa-0 mt-3 mt-md-0 d-flex align-end justify-end"},[_c('v-btn',{attrs:{"color":"green","depressed":""},on:{"click":function($event){return _vm.refresh()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-refresh ")]),_c('span',{staticClass:"white--text font-weight-semibold"},[_vm._v("Actualizar")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.refundsRequests,"loading":_vm.loadingData,"items-per-page":_vm.limit,"server-items-length":_vm.totalItems,"disable-sort":_vm.loadingData,"options":_vm.options,"multi-sort":"","footer-props":{
      'items-per-page-options': [5, 10, 20, 30, 40, 50, 500, 999]
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"px-5 pt-5 pb-5"},[_c('v-chip-group',{staticClass:"mx-3",attrs:{"column":"","multiple":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},_vm._l((_vm.states),function(state){return _c('v-chip',{directives:[{name:"longclick",rawName:"v-longclick",value:(function () { return _vm.longPressChip(state); }),expression:"() => longPressChip(state)"}],key:state.value,attrs:{"filter":"","outlined":"","color":state.color,"disabled":_vm.loadingData}},[_vm._v(" "+_vm._s(state.text)+" ")])}),1)],1)]},proxy:true},{key:"item.user.identifier",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.user ? item.user.identifier : ''))])]}},{key:"item.user",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.user ? item.user.name : '')+" "+_vm._s(item.user ? item.user.surname : ''))])]}},{key:"item.pet",fn:function(ref){
    var item = ref.item;
return [(item.pet.species === 'cat')?_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"small":"","color":"green"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-cat ")]),_vm._v(" "+_vm._s(item.pet.name)+" ")],1):_vm._e(),(item.pet.species === 'dog')?_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"small":"","color":"orange"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-dog ")]),_vm._v(_vm._s(item.pet.name))],1):_vm._e()]}},{key:"item.service",fn:function(ref){
    var item = ref.item;
return [_c('v-col',{staticClass:"ma-0 pa-0 mt-1",staticStyle:{"max-width":"200px"}},[_c('span',[_vm._v(_vm._s(item.service ? item.service.title : '-')+" ")])])]}},{key:"item.updated",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('span',{staticClass:"black--text font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.parseDate(new Date(item.updatedAt)))+" ")])])]}},{key:"item.changelogs",fn:function(ref){
    var item = ref.item;
return [(item.changelogs !== null || item.changelogs.length > 0)?_c('div',[_c('v-chip',{attrs:{"small":"","outlined":"","color":item.changelogs.status.highlightColor}},[_vm._v(_vm._s(item.changelogs.status.name))])],1):_c('div',[_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"x-small":"","outlined":"","color":"red"}},[_vm._v("Sin información")])],1)]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(item.changelogs !== null || item.changelogs.length > 0)?_c('div',[_c('refund-mainteiner',{attrs:{"data":item,"refreshParent":_vm.refresh}})],1):_c('div',[_vm._v(" Sin data ")])]}}])}),_c('overlay',{attrs:{"value":_vm.loadingOverlay}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }