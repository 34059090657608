var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-4 elevation-0"},[_c('v-row',{staticClass:"px-5",staticStyle:{"justify-content":"space-between","align-items":"center","padding":"20px"}},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","md":"4"}},[_c('h1',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Membresías activas")])]),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","md":"8"}},[_c('v-container',{staticClass:"ma-0 pa-0 d-flex align-center justify-end"},[_c('div',{staticClass:"d-flex flex-column justify-end align-center mr-3 ml-6"},[_c('v-select',{attrs:{"items":_vm.companies,"item-text":"name","item-value":"id","label":"Filtrar por empresa","return-object":""},model:{value:(_vm.companySelected),callback:function ($$v) {_vm.companySelected=$$v},expression:"companySelected"}})],1),_c('v-btn',{staticClass:"mb-3",attrs:{"color":"primary","depressed":""},on:{"click":function () { return _vm.downloadCsv(2); }}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-file-download-outline")]),_c('span',[_vm._v("CSV")])],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"mt-3 elevation-0",attrs:{"headers":_vm.headers,"items":_vm.requests,"options":_vm.options,"loading":_vm.loadingData,"items-per-page":_vm.limit,"server-items-length":_vm.totalItems,"sort-desc":_vm.sort,"disable-sort":_vm.loadingData,"multi-sort":"","footer-props":{
      'items-per-page-options': [10, 15, 20]
    }},on:{"update:options":[function($event){_vm.options=$event},_vm.fetchActiveMemberships],"update:sortDesc":function($event){_vm.sort=$event},"update:sort-desc":function($event){_vm.sort=$event}},scopedSlots:_vm._u([{key:"item.userName",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.userName || '—'))])]}},{key:"item.email",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.email || '—'))])]}},{key:"item.petName",fn:function(ref){
    var item = ref.item;
return [(item.species === 'cat')?[_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"small":"","color":"green"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cat")]),_vm._v(" "+_vm._s(item.petName)+" ")],1)]:(item.species === 'dog')?[_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"small":"","color":"orange"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-dog")]),_vm._v(" "+_vm._s(item.petName)+" ")],1)]:[_c('span',[_vm._v(_vm._s(item.petName || '—'))])]]}},{key:"item.companyName",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.companyName || '—'))])]}},{key:"item.planName",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.planName || '—'))])]}}])}),_c('v-snackbar',{attrs:{"color":"green","timeout":_vm.timeout},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"blue","variant":"text"},on:{"click":function($event){_vm.snackbarOk = false}}},[_vm._v("Close")])]},proxy:true}]),model:{value:(_vm.snackbarOk),callback:function ($$v) {_vm.snackbarOk=$$v},expression:"snackbarOk"}},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]),_c('v-snackbar',{attrs:{"color":"red","timeout":_vm.timeout},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"blue","variant":"text"},on:{"click":function($event){_vm.snackbarFailed = false}}},[_vm._v("Close")])]},proxy:true}]),model:{value:(_vm.snackbarFailed),callback:function ($$v) {_vm.snackbarFailed=$$v},expression:"snackbarFailed"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }