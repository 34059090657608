var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"ma-0 my-4 align-center justify-space-between"},[_c('h1',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Usuarios")]),_c('v-btn',{staticClass:"d-none d-md-block",attrs:{"rounded":"","color":"primary","depressed":""},on:{"click":_vm.goToCreateUser}},[_c('span',{staticClass:"d-none d-md-block"},[_vm._v("Agregar Usuario")])]),_c('v-btn',{staticClass:"d-block d-md-none",attrs:{"rounded":"","color":"primary","fab":"","depressed":"","x-small":""},on:{"click":_vm.goToCreateUser}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1),_c('v-dialog',{attrs:{"persistent":"","min-width":"600","max-width":"900","min-height":"600","max-height":"900"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[[_c('user-maintainer',{attrs:{"user":_vm.selectedUser},on:{"update-list":function($event){return _vm.getUsers(this.page, this.limit)},"close-dialog":_vm.closeDialog}})]],2),_c('v-dialog',{attrs:{"persistent":"","min-width":"600","max-width":"900","min-height":"600","max-height":"900"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[[_c('confirmation-modal',{attrs:{"user":_vm.selectedUser},on:{"close-dialog":_vm.closeDeleteDialog,"delete-user":_vm.deleteUser}})]],2),_c('v-dialog',{attrs:{"persistent":"","min-width":"600","max-width":"900","min-height":"600","max-height":"900"},model:{value:(_vm.petsDialog),callback:function ($$v) {_vm.petsDialog=$$v},expression:"petsDialog"}},[[_c('pets-list',{attrs:{"user":_vm.selectedUser},on:{"close-dialog":_vm.closePetsDialog}})]],2)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"options":_vm.options,"loading":_vm.loadingData,"items-per-page":_vm.limit,"server-items-length":_vm.totalItems,"footer-props":{
      'items-per-page-options': [5, 10, 20, 30, 40, 50]
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar","placeholder":"Nombre, correo, RUT...","dense":"","hide-details":"","outlined":"","append-icon":"mdi-magnify"},on:{"click:append":_vm.searchString,"keyup":[_vm.cleanSearch,function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchString.apply(null, arguments)}]},model:{value:(_vm.stringToSearch),callback:function ($$v) {_vm.stringToSearch=$$v},expression:"stringToSearch"}})],1)]},proxy:true},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name)+" "+_vm._s(item.surname))]),(item.role == 'admin')?_c('span',[_c('v-chip',{staticClass:"ml-2",attrs:{"color":"primary","x-small":"","outlined":""}},[_vm._v(" admin ")])],1):_vm._e()]}},{key:"item.rut",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("rut")(item.rut)))])]}},{key:"item.birthday",fn:function(ref){
    var item = ref.item;
return [(item.birthday)?_c('span',[_vm._v(_vm._s(_vm.formatDate(item.birthday)))]):_vm._e()]}},{key:"item.role",fn:function(ref){
    var item = ref.item;
return [_c('v-chip-group',[_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"small":"","outlined":"","color":"primary"}},[_vm._v(_vm._s(item.role))])],1)]}},{key:"item.hasActiveMemberships",fn:function(ref){
    var item = ref.item;
return [(item.hasActiveMemberships)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close-circle")])]}},{key:"item.actions",fn:function(props){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","depressed":"","x-small":"","color":"blue"},on:{"click":function($event){return _vm.openDialog(props.item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),(props.item.role == 'subscriber')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","depressed":"","x-small":"","color":"green"},on:{"click":function($event){return _vm.addPet(props.item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v("mdi-wallet-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Agregar membresía")])]):_vm._e(),(props.item.role == 'subscriber')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","depressed":"","x-small":"","color":"orange"},on:{"click":function($event){return _vm.goToMemberships(props.item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v("mdi-wallet-membership")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver membresías")])]):_vm._e(),(props.item.role == 'subscriber')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","depressed":"","x-small":"","color":"purple"},on:{"click":function($event){return _vm.goToPets(props.item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v("mdi-paw")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver mascotas")])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }