<template>
  <div>
    <!-- Contenedor principal sin sombra -->
    <v-card class="pa-4 elevation-0">
      <v-row
        class="px-5"
        style="justify-content: space-between; align-items: center; padding: 20px"
      >
        <!-- Título -->
        <v-col cols="12" md="4" class="my-0 py-0">
          <h1 class="text-h5 font-weight-bold">Membresías activas</h1>
        </v-col>

        <!-- Filtro por empresa y botón CSV -->
        <v-col cols="12" md="8" class="my-0 py-0">
          <v-container class="ma-0 pa-0 d-flex align-center justify-end">
            <!-- Dropdown para filtrar -->
            <div class="d-flex flex-column justify-end align-center mr-3 ml-6">
              <v-select
                v-model="companySelected"
                :items="companies"
                item-text="name"
                item-value="id"
                label="Filtrar por empresa"
                return-object
              ></v-select>
            </div>

            <!-- Botón para descargar CSV -->
            <v-btn class="mb-3" color="primary" depressed @click="() => downloadCsv(2)">
              <v-icon dark>mdi-file-download-outline</v-icon>
              <span>CSV</span>
            </v-btn>
          </v-container>
        </v-col>
      </v-row>
    </v-card>

    <!-- Data Table -->
    <v-data-table
      :headers="headers"
      :items="requests"
      :options.sync="options"
      :loading="loadingData"
      :items-per-page="limit"
      :server-items-length="totalItems"
      :sort-desc.sync="sort"
      :disable-sort="loadingData"
      multi-sort
      class="mt-3 elevation-0"
      :footer-props="{
        'items-per-page-options': [10, 15, 20]
      }"
      @update:options="fetchActiveMemberships"
    >
      <template v-slot:item.userName="{ item }">
        <span>{{ item.userName || '—' }}</span>
      </template>
      <template v-slot:item.email="{ item }">
        <span>{{ item.email || '—' }}</span>
      </template>
      <template v-slot:item.petName="{ item }">
        <template v-if="item.species === 'cat'">
          <v-chip small color="green" class="white--text font-weight-bold">
            <v-icon left>mdi-cat</v-icon>
            {{ item.petName }}
          </v-chip>
        </template>
        <template v-else-if="item.species === 'dog'">
          <v-chip small color="orange" class="white--text font-weight-bold">
            <v-icon left>mdi-dog</v-icon>
            {{ item.petName }}
          </v-chip>
        </template>
        <template v-else>
          <span>{{ item.petName || '—' }}</span>
        </template>
      </template>

      <template v-slot:item.companyName="{ item }">
        <span>{{ item.companyName || '—' }}</span>
      </template>
      <template v-slot:item.planName="{ item }">
        <span>{{ item.planName || '—' }}</span>
      </template>
    </v-data-table>

    <!-- Snackbars -->
    <v-snackbar v-model="snackbarOk" color="green" :timeout="timeout">
      {{ successMessage }}
      <template v-slot:actions>
        <v-btn color="blue" variant="text" @click="snackbarOk = false">Close</v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="snackbarFailed" color="red" :timeout="timeout">
      {{ errorMessage }}
      <template v-slot:actions>
        <v-btn color="blue" variant="text" @click="snackbarFailed = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import CompaniesService from '../services/companie.service';
import MembershipsService from '../services/memberships.service';

export default {
  data() {
    return {
      companies: [{ id: null, name: 'Todos' }],
      companySelected: { id: null, name: 'Todos' },
      snackbarOk: false,
      snackbarFailed: false,
      successMessage: '',
      errorMessage: '',
      timeout: 3000,
      headers: [
        { text: 'Colaborador', value: 'userName', sortable: true },
        { text: 'Correo', value: 'email', sortable: true },
        { text: 'Mascota', value: 'petName', sortable: true },
        { text: 'Empresa', value: 'companyName', sortable: true },
        { text: 'Nombre del Plan', value: 'planName', sortable: true }
      ],
      requests: [],
      options: {},
      limit: 10,
      totalItems: 0,
      sort: [],
      loadingData: false
    };
  },
  watch: {
    companySelected: {
      handler() {
        this.fetchActiveMemberships();
      },
      deep: true
    }
  },
  methods: {
    async getCompanies() {
      this.loadingData = true;
      const res = await CompaniesService.getCompanies(1, 100);
      if (res.status === 'ok') {
        this.companies = [...this.companies, ...res.data.companies];
      }
      this.loadingData = false;
    },
    async fetchActiveMemberships() {
      this.loadingData = true;
      try {
        const { page, itemsPerPage } = this.options;
        const { status, data } = await MembershipsService.getActiveDxpMemberships(
          page || 1,
          itemsPerPage || this.limit,
          this.stringToSearch || null,
          this.companySelected?.id || null
        );

        if (status === 'ok') {
          this.requests = data.memberships;
          console.log('Requests:', this.requests);
          this.totalItems = data.total;
        } else {
          this.requests = [];
          this.totalItems = 0;
        }
      } catch (error) {
        console.error('Error fetching active memberships:', error);
        this.requests = [];
        this.totalItems = 0;
      } finally {
        this.loadingData = false;
      }
    },
    async downloadCsv(status) {
      const { status: responseStatus, data } = await MembershipsService.getExportedDxpCsv(
        status,
        this.companySelected?.id || null,
        this.stringToSearch || null
      );

      if (responseStatus === 'ok') {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `dxp_memberships_${new Date().toISOString().split('T')[0]}.xlsx`;
        link.click();
      } else {
        console.error('Error downloading CSV');
      }
    }
  },
  mounted() {
    this.getCompanies();
    this.fetchActiveMemberships();
  }
};
</script>
