export default class CurrencyUtils {
  static formatPriceFromNumber(price) {
    return price
      .toString()
      .split('')
      .reverse()
      .reduce((acc, char, index) => {
        return char + (index && index % 3 === 0 ? '.' : '') + acc;
      }, '');
  }

  static cleanCurrencyFormat(price) {
    return price.replace(/[^0-9]/g, '');
  }
}
