import axios from 'axios';

const URL_V2 = process.env.VUE_APP_BACKEND_URL_V2;
const ADMIN_URL = process.env.VUE_APP_ADMIN_URL;

export default class RefundsServices {
  static async getRefunds(page, limit, states) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      let s = '';
      for (var s1 of states) {
        if (s1 == 0) {
          s = `${s}&register=true`;
        } else if (s1 == 1) {
          s = `${s}&review=true`;
        } else if (s1 == 2) {
          s = `${s}&modify=true`;
        } else if (s1 == 3) {
          s = `${s}&rejected=true`;
        } else if (s1 == 4) {
          s = `${s}&approved=true`;
        }
      }
      const url = `${ADMIN_URL}/refunds?page=${page}&limit=${limit}${s}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      const refunds = response.data.data;
      return { status: 'ok', data: refunds };
    } catch (err) {
      return { status: 'error' };
    }
  }

  static async getAllRefunds(page, limit, query, sort, userId, search, serviceId, petId) {
    try {
      const accessToken = JSON.parse(localStorage.getItem('accessToken'));
      let url = `${URL_V2}/refunds/all/refundrequests?page=${page}&limit=${limit}`;
      if (userId != null && userId.length != 0) {
        url = url + `&userId=${userId}`;
      }
      if (query != null && query.length != 0) {
        url = url + `&status=${query}`;
      }
      if (sort != null && sort.length != 0) {
        url = url + `&sort=${sort.join(',')}`;
      }
      if (search != null && search.length != 0) {
        url = url + `&search=${search}`;
      }
      if (serviceId != null && serviceId.length != 0) {
        url = url + `&serviceId=${serviceId}`;
      }
      if (petId != null && petId.length != 0) {
        url = url + `&serviceId=${petId}`;
      }
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.data.status === 'ok') {
        const refunds = response.data.data;
        return { status: 'ok', data: refunds };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async getChangelogStatuses() {
    try {
      let url = `${URL_V2}/refunds/changelog/statuses`;
      const response = await axios.get(url);
      console.log('getChangelogStatuses', response);

      if (response.data.status === 'ok') {
        const statuses = response.data.data;
        return { status: 'ok', data: statuses };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async updateChangelogStatus(data) {
    const accessToken = JSON.parse(localStorage.getItem('accessToken'));
    try {
      let url = `${URL_V2}/refunds/create/changelog`;
      const response = await axios.post(url, data, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.status == 200) {
        return { status: 'ok', data: response.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }

  static async createRefund(data) {
    const accessToken = JSON.parse(localStorage.getItem('accessToken'));
    try {
      let url = `${ADMIN_URL}/refunds/create-refund`;
      const response = await axios.post(url, data, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      if (response.status == 200) {
        return { status: 'ok', data: response.data };
      } else {
        return { status: 'error' };
      }
    } catch (error) {
      return { status: 'error' };
    }
  }
  static async getRefundDetails(refundId) {
    const accessToken = JSON.parse(localStorage.getItem('accessToken'));
    try {
      let url = `${ADMIN_URL}/refunds/${refundId}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      return response.data.data;
    } catch (error) {
      console.error('Error fetching refund details:', error);
      throw error;
    }
  }

  static async postRecordAndRefundDetails(body) {
    const accessToken = JSON.parse(localStorage.getItem('accessToken'));
    try {
      console.log(body);
      const url = `${ADMIN_URL}/refunds/commit/status-records`;
      const response = await axios.post(url, body, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      return response.data.data;
    } catch (error) {
      console.error('Error post details:', error);
      throw error;
    }
  }
}
