<template>
  <div class="ml-5">
    <v-overlay :value="isLoading" fixed>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <h1 class="text-h5 font-weight-bold">Agregar Mascota</h1>
    <h3 class="mt-10">Datos de la mascota</h3>
    <v-form ref="form" v-model="valid" :disabled="isLoading">
      <!-- Primera fila -->
      <v-row class="my-1">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="pet.name"
            label="Nombre de la mascota"
            :rules="[rules.required]"
            outlined
            dense
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            v-model="pet.sex"
            :items="sexOptions"
            label="Sexo"
            :rules="[rules.required]"
            item-text="text"
            item-value="value"
            outlined
            dense
            hide-details="auto"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-menu
            ref="birthdateMenu"
            v-model="birthdateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="pet.birthdate"
                prepend-icon="mdi-calendar"
                label="Fecha de nacimiento"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="[rules.required]"
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="pet.birthdate"
              @input="birthdateMenu = false"
              locale="es"
              :min="'2004-01-01'"
              :max="new Date().toISOString().slice(0, 10)"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <!-- Segunda fila -->
      <v-row class="my-1">
        <v-col cols="12" md="3">
          <v-select
            v-model="pet.species"
            :items="speciesOptions"
            label="Especie"
            :rules="[rules.required]"
            item-text="text"
            item-value="value"
            outlined
            dense
            hide-details="auto"
            @change="getBreeds"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            v-model="pet.breed"
            :items="breedOptions"
            label="Raza"
            :rules="[rules.breedRequired]"
            item-text="text"
            item-value="value"
            outlined
            dense
            hide-details="auto"
          ></v-select>
        </v-col>
      </v-row>

      <!-- Eliminamos el botón "Obtener Planes" -->

      <!-- Tercera fila -->
      <h3 class="mt-3">Datos de membresía</h3>
      <v-row class="my-1">
        <v-col cols="12" md="4">
          <v-select
            v-model="membership.plan"
            :items="planOptions"
            label="Plan"
            :rules="[rules.required]"
            item-text="text"
            item-value="value"
            :loading="loadingPlans"
            outlined
            dense
            hide-details="auto"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            v-model="membership.paymentMethod"
            :items="paymentMethodOptions"
            label="Modalidad de pago"
            :rules="[rules.required]"
            item-text="text"
            item-value="value"
            outlined
            dense
            hide-details="auto"
          ></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            v-model="membership.coupon"
            :items="couponOptions"
            item-text="text"
            item-value="value"
            :loading="loadingCoupons"
            :active="canSelectCoupon"
            outlined
            dense
            hide-details="auto"
            label="Cupón"
          ></v-select>
        </v-col>
      </v-row>

      <!-- Cuarta fila -->
      <v-row class="my-1">
        <v-col cols="12" md="3">
          <v-menu
            ref="startDateMenu"
            v-model="startDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="membership.startDate"
                prepend-icon="mdi-calendar"
                label="A contar de"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="[rules.required]"
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="membership.startDate"
              @input="startDateMenu = false"
              locale="es"
              :min="'2004-01-01'"
              :max="new Date().toISOString().slice(0, 10)"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            v-model="membership.status"
            :items="statusOptions"
            label="Estado membresía"
            :rules="[rules.required]"
            item-text="text"
            item-value="value"
            outlined
            dense
            hide-details="auto"
          ></v-select>
        </v-col>
      </v-row>

      <!-- Modal de Confirmación -->
      <v-dialog v-model="showConfirmationDialog" persistent max-width="500">
        <v-card>
          <v-card-title class="headline">
            Confirmación
          </v-card-title>
          <v-card-text>
            Esta acción creará una membresía con estado:
            <strong>{{ getMembershipStatusText }}</strong
            >. ¿Deseas continuar?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="showConfirmationDialog = false">Cancelar</v-btn>
            <v-btn color="primary" @click="confirmSubmit">Aceptar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Botones -->
      <v-row class="mt-4">
        <v-col cols="12" class="text-right">
          <v-btn color="primary" @click="showConfirmation">Guardar</v-btn>
          <v-btn text @click="cancel">Cancelar</v-btn>
        </v-col>
      </v-row>

      <!-- Snackbar para mensajes de éxito -->
      <v-snackbar v-model="snackbarSuccess" :timeout="3000" color="green" top>
        {{ snackbarMessage }}
        <v-btn color="white" text @click="snackbarSuccess = false">Cerrar</v-btn>
      </v-snackbar>

      <!-- Snackbar para mensajes de error -->
      <v-snackbar v-model="snackbarError" :timeout="3000" color="red" top>
        {{ snackbarMessage }}
        <v-btn color="white" text @click="snackbarError = false">Cerrar</v-btn>
      </v-snackbar>
    </v-form>
  </div>
</template>

<script>
import PetsService from '../services/pets.service';
import PlansService from '../services/plans.service';
import CouponsService from '../services/coupons.service';
import MembershipService from '../services/memberships.service';
import UserService from '../services/users.service';

export default {
  props: ['userId'],
  data() {
    return {
      isLoading: false,
      user: null,
      valid: false,
      canSelectCoupon: false,
      pet: {
        name: '',
        species: null,
        birthdate: '',
        sex: null,
        breed: null,
        age: { quantity: 0, unit: '' }
      },
      membership: {
        plan: null,
        paymentMethod: null,
        coupon: null,
        startDate: '',
        status: null
      },
      sexOptions: [
        { text: 'Macho', value: 'male' },
        { text: 'Hembra', value: 'female' }
      ],
      speciesOptions: [
        { text: 'Perro', value: 'dog' },
        { text: 'Gato', value: 'cat' }
      ],
      breedOptions: [],
      planOptions: [],
      paymentMethodOptions: [
        { text: 'Anual', value: 1 },
        { text: 'Mensual', value: 2 }
      ],
      couponOptions: [],
      statusOptions: [
        { text: 'Activo', value: 2 },
        { text: 'Desactivado', value: 4 },
        { text: 'Pendiente de pago', value: 5 }
      ],
      birthdateMenu: false,
      startDateMenu: false,
      rules: {
        required: value => !!value || 'Este campo es requerido',
        breedRequired: value => value !== undefined || 'Este campo es requerido'
      },
      loadingBreeds: false,
      loadingPlans: false,
      loadingCoupons: false,
      loading: false,
      showConfirmationDialog: false,
      snackbarSuccess: false,
      snackbarError: false,
      snackbarMessage: ''
    };
  },
  mounted() {
    this.fetchUser();
  },
  methods: {
    // Obtener planes disponibles
    /*async getPlans() {
      this.loadingPlans = true;
      try {
        if (!this.user || !this.user.type) {
          throw new Error('El tipo de usuario no está disponible');
        }

        const userType = this.user.type.toUpperCase();

        const response = await PlansService.getPlans(1, 100, true, userType);
        if (response.status === 'ok') {
          this.planOptions = response.data.plansV2.map(plan => ({
            text: plan.name,
            value: plan.id
          }));
        } else {
          console.error('Error al obtener los planes:', response.message);
          this.snackbarError = true;
          this.snackbarMessage = 'Error al obtener los planes';
        }
      } catch (err) {
        console.error('Error al obtener los planes:', err.message);
        this.snackbarError = true;
        this.snackbarMessage = 'Error al obtener los planes';
      } finally {
        this.loadingPlans = false;
      }
    },*/

    async getAvailablePlans() {
      try {
        this.loadingPlans = true;
        const response = await PlansService.getPlansByUserId(this.userId);
        if (response.status === 'ok') {
          this.planOptions = response.data.map(plan => ({
            text: `${plan.name} - ${plan.type.toUpperCase()} - ${plan.internalDescription}`,
            value: plan.id
          }));
        }
        this.loadingPlans = false;
      } catch (err) {
        console.error('Error al obtener los planes:', err.message);
        this.snackbarError = true;
        this.snackbarMessage = 'Error al obtener los planes';
      }
    },

    // Obtener cupones activos
    async getCoupons() {
      this.loadingCoupons = true;
      try {
        const response = await CouponsService.getActiveCoupons(this.user.country);
        if (response.status === 'ok') {
          this.couponOptions = response.data.map(coupon => ({
            text: coupon.name,
            value: coupon.code
          }));
        } else {
          console.error('Error al obtener los cupones:', response.message);
        }
      } catch (error) {
        console.error('Error al obtener los cupones:', error);
      } finally {
        this.loadingCoupons = false;
      }
    },
    // Obtener las razas según la especie seleccionada
    async getBreeds() {
      if (!this.pet.species) return;
      this.loadingBreeds = true;
      try {
        const response = await PetsService.getBreeds(this.pet.species);
        if (response.status === 'ok') {
          this.breedOptions = response.data.map(breed => ({
            text: breed.name,
            value: breed.id
          }));
          this.breedOptions.unshift({ text: 'Mestizo', value: null });
        } else {
          console.error('Error al obtener las razas:', response.message);
        }
      } catch (err) {
        console.error('Error al obtener las razas:', err.message);
      } finally {
        this.loadingBreeds = false;
      }
    },
    // Calcular la edad de la mascota
    getAge(birthdate) {
      const now = new Date();
      const birth = new Date(birthdate);

      let years = now.getFullYear() - birth.getFullYear();
      let months = now.getMonth() - birth.getMonth();

      if (months < 0) {
        years--;
        months += 12;
      }

      if (years > 0) {
        return { quantity: years, unit: 'Y' };
      } else {
        return { quantity: months, unit: 'M' };
      }
    },
    // Mostrar el diálogo de confirmación
    showConfirmation() {
      if (this.$refs.form.validate()) {
        this.showConfirmationDialog = true;
      }
    },
    // Confirmar y enviar el formulario
    confirmSubmit() {
      this.showConfirmationDialog = false;
      this.performSubmit();
    },
    async fetchUser() {
      try {
        const response = await UserService.getUserById(this.userId);
        if (response.status === 'ok') {
          this.user = response.data;
          // Llamo a getCoupons() y getPlans() después de obtener el usuario
          await this.getCoupons();
          //await this.getPlans();
          await this.getAvailablePlans();
        } else {
          console.error('Error al obtener los datos del usuario:', response.message);
        }
      } catch (error) {
        console.error('Error al obtener los datos del usuario:', error);
      } finally {
        //this.isLoading = false;
      }
    },
    // Enviar el formulario
    async performSubmit() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        try {
          // Calcular la edad de la mascota
          this.pet.age = this.getAge(this.pet.birthdate);

          // Preparar los datos de la mascota
          const petData = {
            name: this.pet.name,
            birthdate: this.pet.birthdate,
            species: this.pet.species,
            owner: this.userId,
            sex: this.pet.sex,
            breedId: this.pet.breed ? this.pet.breed.value : null,
            isPurebred: this.pet.breed ? true : false // Suponiendo que si tiene breedId, es de raza pura
          };

          // Preparar los datos de la membresía
          const membershipData = {
            startDate: this.membership.startDate,
            planId: this.membership.plan,
            paymentInterval: this.membership.paymentMethod,
            status: this.membership.status,
            userId: this.userId
          };

          // Preparar el cuerpo de la solicitud
          const requestBody = {
            pet: petData,
            membership: membershipData,
            userId: this.userId,
            coupon: this.membership.coupon || null // Incluir el cupón si existe
          };

          // Llamar al endpoint del backend
          const response = await MembershipService.createPetAndMembership(requestBody);

          if (response.status === 'ok') {
            this.snackbarSuccess = true;
            this.snackbarMessage = 'Mascota y membresía agregadas exitosamente';
            /*setTimeout(() => {
              this.$router.push({ name: 'Pets by User', params: { userId: this.userId } });
            }, 3000);*/
          } else {
            console.error('Error al agregar la mascota y membresía:', response.message);
            this.snackbarError = true;
            this.snackbarMessage = 'Error al agregar la mascota y membresía';
            console.log('soy response', response);
          }
        } catch (error) {
          console.error('Error en la solicitud:', error);
          this.snackbarError = true;
          this.snackbarMessage = 'Error en la solicitud';
          console.log('soy error', error);
        } finally {
          this.isLoading = false;
        }
      }
    },
    // Cancelar y volver atrás
    cancel() {
      this.$router.push({ name: 'Pets by User', params: { userId: this.userId } });
    }
  },
  computed: {
    // Obtener el texto del estado de la membresía
    getMembershipStatusText() {
      const statusValue = this.membership.status;
      const statusOption = this.statusOptions.find(option => option.value === statusValue);
      return statusOption ? statusOption.text : '';
    }
  }
};
</script>
