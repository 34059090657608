import { ACTIVE_MEMBERSHIP, DEACTIVE_MEMBERSHIP, PENDING_PAYMENT } from './constants';

export default class MiscUtils {
  static copyToClipboard(data) {
    const textArea = document.createElement('textarea');
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = 0;
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = data;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      var successful = document.execCommand('copy');
      document.body.removeChild(textArea);
      return successful;
    } catch (err) {
      document.body.removeChild(textArea);
      return false;
    }
  }

  static parseMembershipStatus(status) {
    if (status == ACTIVE_MEMBERSHIP) return 'Activo';
    else if (status == DEACTIVE_MEMBERSHIP) return 'Desactivado';
    else if (status == PENDING_PAYMENT) return 'Pendiente de pago';
    else return 'Desconocido';
  }
}
