<template>
  <div>
    <v-row class="ma-0 my-4 align-center justify-space-between">
      <h1 class="text-h5 font-weight-bold">Solicitudes de Reembolso</h1>

      <v-col cols="12" md="6" class="my-0 py-0">
        <v-container class="ma-0 pa-0 mt-3 mt-md-0 d-flex align-end justify-end">
          <v-btn color="green" depressed @click="refresh()">
            <v-icon color="white"> mdi-refresh </v-icon>
            <span class="white--text font-weight-semibold">Actualizar</span>
          </v-btn>
        </v-container>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="refundsRequests"
      :loading="loadingData"
      :items-per-page="limit"
      :server-items-length="totalItems"
      :disable-sort="loadingData"
      :options.sync="options"
      multi-sort
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 30, 40, 50, 500, 999]
      }"
    >
      <template v-slot:top>
        <v-row class="px-5 pt-5 pb-5">
          <v-chip-group v-model="search" column multiple class="mx-3">
            <v-chip
              filter
              outlined
              v-for="state in states"
              v-longclick="() => longPressChip(state)"
              :key="state.value"
              :color="state.color"
              :disabled="loadingData"
            >
              {{ state.text }}
            </v-chip>
          </v-chip-group>
        </v-row>
      </template>

      <template v-slot:item.user.identifier="{ item }">
        <span>{{ item.user ? item.user.identifier : '' }}</span>
      </template>

      <template v-slot:item.user="{ item }">
        <span>{{ item.user ? item.user.name : '' }} {{ item.user ? item.user.surname : '' }}</span>
      </template>

      <template v-slot:item.pet="{ item }">
        <v-chip
          v-if="item.pet.species === 'cat'"
          small
          color="green"
          class="white--text font-weight-bold"
          ><v-icon left> mdi-cat </v-icon> {{ item.pet.name }}
        </v-chip>
        <v-chip
          v-if="item.pet.species === 'dog'"
          small
          color="orange"
          class="white--text font-weight-bold"
          ><v-icon left> mdi-dog </v-icon>{{ item.pet.name }}</v-chip
        >
      </template>

      <template v-slot:item.service="{ item }">
        <v-col class="ma-0 pa-0 mt-1" style="max-width: 200px;">
          <span>{{ item.service ? item.service.title : '-' }} </span>
        </v-col>
      </template>

      <template v-slot:item.updated="{ item }">
        <div>
          <span class="black--text font-weight-semibold">
            {{ parseDate(new Date(item.updatedAt)) }}
          </span>
        </div>
      </template>

      <template v-slot:item.changelogs="{ item }">
        <div v-if="item.changelogs !== null || item.changelogs.length > 0">
          <v-chip small outlined :color="item.changelogs.status.highlightColor">{{
            item.changelogs.status.name
          }}</v-chip>
        </div>
        <div v-else>
          <v-chip x-small outlined color="red" class="white--text font-weight-bold"
            >Sin información</v-chip
          >
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div v-if="item.changelogs !== null || item.changelogs.length > 0">
          <refund-mainteiner :data="item" :refreshParent="refresh"></refund-mainteiner>
        </div>
        <div v-else>
          Sin data
        </div>
      </template>
    </v-data-table>
    <overlay :value="loadingOverlay"></overlay>
  </div>
</template>

<script>
import RefundServices from '../services/refunds.service';
import RefundMainteiner from '../components/RefundMainteiner';

import Overlay from '../components/Overlay.vue';
import DateUtils from '../utils/date.utils';

export default {
  components: {
    RefundMainteiner,
    Overlay
  },
  data() {
    return {
      updateChangelogError: false,
      updateChangelogErrorMsg: '',
      updateChangelogOk: false,
      updateChangelogOkMsg: '',
      loadingData: true,
      loadingOverlay: false,
      confirmDialog: false,
      selectedItem: null,
      limit: 10,
      page: 1,
      totalItems: 0,
      sort: [],
      search: [0, 1, 2, 3, 4],
      options: {},
      states: [
        {
          text: 'Registrado',
          value: 0,
          color: '#104B94',
          icon: 'mdi-clock-time-nine-outline'
        },
        {
          text: 'En revisión',
          value: 100,
          color: '#CC7A00',
          icon: 'mdi-clock-time-nine-outline'
        },
        {
          text: 'Por corregir',
          value: 200,
          color: '#5E35B1',
          icon: 'mdi-account-edit'
        },
        {
          text: 'Rechazado',
          value: 300,
          color: 'red',
          icon: 'mdi-close-thick'
        },
        {
          text: 'Aprobado',
          value: 400,
          color: 'green',
          icon: 'mdi-check-bold'
        }
      ],

      headers: [
        {
          text: 'Identificador',
          align: 'start',
          sortable: false,
          value: 'user.identifier'
        },
        {
          text: 'Usuario',
          align: 'start',
          sortable: false,
          value: 'user'
        },
        { text: 'Mascota', sortable: false, value: 'pet' },

        { text: 'Servicio', align: 'center', sortable: false, value: 'service' },
        { text: 'Actualizada: ', align: 'center', sortable: false, value: 'updated' },

        { text: 'Estado', align: 'center', sortable: false, value: 'changelogs' },
        { text: 'Acciones', align: 'center', sortable: false, value: 'actions' }
      ],
      refundsRequests: [],
      userId: null,
      petId: null,
      serviceId: null
    };
  },
  mounted() {},
  created() {
    this.getRefunds(this.page, this.limit);
  },
  watch: {
    options: {
      handler() {
        this.page = this.options.page;
        this.limit = this.options.itemsPerPage;
        this.getRefunds(this.page, this.limit);
      },
      deep: true
    },

    search: {
      handler() {
        this.page = 1;
        this.limit = 10;
        this.sort = [];
        this.getRefunds(this.page, this.limit);
      },
      deep: true
    }
  },
  methods: {
    parseDate(date) {
      return DateUtils.formatToChileanDate(date);
    },
    refresh() {
      this.updateChangelogOk = false;
      this.updateChangelogOkMsg = '';

      this.getRefunds(this.page, this.limit);
    },
    async getRefunds(page, limit) {
      try {
        this.loadingData = true;
        this.loadingOverlay = true;
        const res = await RefundServices.getRefunds(page, limit, this.search);
        if (res.status === 'ok') {
          this.refundsRequests = res.data.result;
          this.totalItems = res.data.totalItems;
        }
        this.loadingData = false;
        this.loadingOverlay = false;
      } catch (err) {
        this.loadingData = false;
        this.loadingOverlay = false;
        console.error(err);
      }
    },
    async longPressChip(status) {
      if (this.search.length == 1 && this.search[0] == status.value) {
        this.search = this.statuses.filter(s => s.value != status.value).map(s => s.value);
      } else {
        this.search = [status.value];
      }
    }
  }
};
</script>
